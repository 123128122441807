import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { FilterData } from "@/core/types/gws-agent/dashboard/FilterData";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { formatInternationalDate } from "@/core/helpers/date-format";
const authStore = useAuthenticationStore();

export const useDashboardMedicalStore = defineStore({
  id: "dashboardMedicalStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        date: [new Date(), new Date()],
      } as FilterData,
      stateDisplayData: {
        TOTAL: 0,
        MEDICAL_REQUESTED: 0,
        MEDICAL_PROCESSED: 0,
        MEDICAL_COMPLETED: 0,
        MEDICAL_PENDING: 0,
        MEDICAL_COMPLETED_FIT: 0,
        MEDICAL_COMPLETED_UNFIT: 0,
      },
      stateFaskes: {
        name: "",
        address: "",
        siup: "",
        npwp: null,
        license_start_date: null,
        license_end_date: null,
        logo_file: "",
      },
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    displayDataFaskes(state) {
      return state.stateFaskes;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      const [startDate, endDate] = this.stateFilterData.date;
      const params = {
        start_date: formatInternationalDate(startDate.toString()),
        end_date: formatInternationalDate(endDate.toString()),
        facility_id: authStore?.user?.org_id || "",
      };

      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `dashboards/medical_status?${new URLSearchParams(params).toString()}`
        );

        const { medical_status } = response.data.data;

        this.stateDisplayData = medical_status;
      } catch (error) {
        console.log(error);
      }

      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `medical/facilities/${authStore?.user?.org_id}`
        );

        const faskesMed = response.data.data;

        this.stateFaskes = faskesMed;
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
  },
});
